import componentJson from './components/ricosviewer/.component.json';

const RICH_CONTENT_WIDGET_ID = componentJson.id;
const CONNECT_TO_DATA_EV_ID = `${RICH_CONTENT_WIDGET_ID}:ConnectToData`;
const EDIT_CONTENT_EV_ID = `${RICH_CONTENT_WIDGET_ID}:EditContent`;
const EDIT_CONTENT_ACTION_ID = 'richcontent_live_preview_gfpp_desktop_main_action';
const DATA_BINDING_APP_DEF_ID = 'dataBinding';
const RICH_CONTENT_ROLE = 'richContentRole';
const TOKEN = 'token';
const NICKNAME = 'richContent';
const MAIN_ARTICLE_ID = '3798db91-a4c7-4385-9f26-d2f1eaeed7b6';
const DESIGN_ARTICLE_ID = '63001f71-f2e1-4c96-bb88-11c7caf9f857';
const DISPLAY_ARTICLE_ID = '0f775cb1-ad60-49c1-ab7a-7f96987c62d5';
const SUPPORT_ARTICLE_ID = 'bc0b40f5-7451-4a50-ac97-7deb0024f380';
const GFPP_HELP_ARTICLE_ID = '13445ec9-34df-4631-88b9-90b379c3a3a7';

export {
  RICH_CONTENT_WIDGET_ID,
  CONNECT_TO_DATA_EV_ID,
  EDIT_CONTENT_EV_ID,
  EDIT_CONTENT_ACTION_ID,
  DATA_BINDING_APP_DEF_ID,
  RICH_CONTENT_ROLE,
  TOKEN,
  NICKNAME,
  MAIN_ARTICLE_ID,
  DESIGN_ARTICLE_ID,
  DISPLAY_ARTICLE_ID,
  SUPPORT_ARTICLE_ID,
  GFPP_HELP_ARTICLE_ID,
};
